@use '@angular/material' as mat;
@import "styles/variables";
@import "styles/mixins";

@import "/src/app/pages/dynamic-form/components/input-date/input-date.global";
@import "/src/app/pages/dynamic-form/components/input-text/input-text.global";

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: $color-font-primary;
}

.primary-bg {
  background: $color-primary;
  color: white;
}

.accent-bg {
  background: $color-accent;
}

.primary-color {
  color: $color-primary;
}

.closecare-button.mat-raised-button,
.closecare-button.mat-stroked-button,
.closecare-button.mat-flat-button {
  border-radius: 32px !important;
  height: 50px;
  min-height: 50px;
  font-size: 16px;
  font-weight: normal;
}

.mat-stroked-button.mat-primary {
  border-color: mat.get-color-from-palette($app-primary);
}

.mat-stroked-button.light {
  border-color: white;
}

.mat-stroked-button.mat-accent {
  border-color: mat.get-color-from-palette($app-accent);
}

.primary-bg.mat-button-disabled {
  background: lightgrey;
}

.app-digit-box {
  border: 1px solid $color-almost-white;
  border-radius: 8px;
  display: block;
  width: 56px;
  height: 56px;
  background-color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: all 0.3s ease 0s;
}

.light-text {
  color: $color-light-text;
}

/* mat-radio-button*/
.mat-radio-container {
  height: 30px;
  width: 30px;
}

.mat-radio-outer-circle {
  height: 30px;
  width: 30px;
  border-color: $color-light-text;
}

.mat-radio-inner-circle {
  height: 30px;
  width: 30px;
}

.mat-radio-button .mat-radio-ripple {
  height: 60px;
  /*double of your required circle radius*/
  width: 60px;
  /*double of your required circle radius*/
  left: calc(50% - 30px);
  /*'30px'-same as your required circle radius*/
  top: calc(50% - 30px);
  /*'30px'-same as your required circle radius*/
}

.mat-radio-label-content {
  font-size: 16px;
  color: $color-light-text;
}

.mat-radio-checked .mat-radio-label-content {
  color: $color-primary;
}

/** wrap label */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox {
  .mat-checkbox-frame {
    border-width: 1px;
  }

  .mat-checkbox-inner-container {
    margin-top: 4px;
    /** keep checkbox on first line */
    margin-right: 12px;
  }
}

.mat-checkbox-frame,
.mat-checkbox-background,
.mat-checkbox-anim-unchecked-checked .mat-checkbox-anim-checked-unchecked {
  transform: scale(1.5);
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /** wrap label ie */
  .mat-checkbox-layout {
    display: block !important;
  }

  .mat-checkbox-inner-container {
    margin-top: 0 !important;
  }
}

.scanner video {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100% !important;
  width: auto !important;
}

.app-full-screen-dialog.cdk-overlay-pane {
  width: 100%;
  height: 100%;
}

.app-full-screen-dialog .mat-dialog-container {
  padding: 0;
}

.app-dialog {
  max-width: 100% !important;

  .mat-dialog-container {
    width: 346px;
    border-radius: 20px;
  }
}

.small-dialog {
  .mat-dialog-container {
    border-radius: 20px;
  }
}

.closecare-logo {
  font-family: "Comfortaa", sans-serif;
}

a {
  color: $color-primary;
}

.color-primary {
  color: $color-primary;
}

.color-danger {
  color: $color-warn;
}

.color-disabled {
  color: $color-dark-grey;
}

.color-ciano {
  color: $color-ciano;
}

.color-success {
  color: $color-success-green;
}

.border-color-primary {
  border-color: $color-primary !important;
}

.mat-form-field-label {
  color: $color-light-grey;
}

.mat-datepicker-toggle {
  color: $color-light-grey;
}

.timepicker {
  .timepicker__header {
    background-color: $color-primary !important;
  }

  .clock-face__clock-hand {
    background-color: $color-primary !important;

    &::after {
      background-color: $color-primary !important;
    }

    &::before {
      border-color: $color-primary !important;
    }
  }

  .clock-face__number {
    >span.active {
      background-color: $color-primary !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: $color-primary !important;
  }
}

.answer-bottom-sheet {
  border-radius: 30px 30px 0 0;
}

mat-bottom-sheet-container {
  padding: 20px 32px !important;
}

.otp__container {
  @include display-flex(row, space-between);
}

.otp__input {
  width: 44px !important;
  height: 44px !important;
  padding: 0 !important;
  border-radius: 22px !important;
  border: none !important;
  box-shadow: $shadow;
  margin: 0 !important;

  &:focus {
    outline: none;
  }
}

.dialog-picker {
  .ngx-timepicker-control {
    width: 100% !important;
  }

  .ngx-timepicker-control,
  .ngx-timepicker-control__input {
    height: 60px !important;
  }

  .ngx-timepicker-control--active:after {
    top: calc(100% - 1px);
  }

  .ngx-timepicker-control__arrows {
    top: 5px !important;
    height: calc(100% - 10px);
    justify-content: space-between;
  }

  .ngx-timepicker-control__arrow {
    font-size: 16px !important;
  }

  .ngx-timepicker__toggle {
    display: none;
  }
}

.file__modal {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.chat__button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 25px !important;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
    border: 2px solid $color-primary;
  }

  &[disabled] {
    opacity: 0.6;
    color: white;
  }
}

.chat__button--negative {
  color: $color-primary;
  background: white;
  box-shadow: $shadow;
}

.file-render {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.file-render__image {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.file-render__image--cover {
    img {
      object-fit: cover;
    }
  }
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 16px 32px 32px 32px;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      #ffffff,
      #ffffff,
      #ffffff,
      #ffffff,
      #ffffff);
}

.button-container button {
  width: 100%;
}

.childcare-dependent-dialog, .childcare-institution-dialog {
  .mat-dialog-container {
    border-radius: 24px;

    & .mat-dialog-content {
      overflow: auto;

      &.on-loading {
        text-align: center;
        padding-bottom: 24px;
      }
    }
  }
}

.month-selector {
  height: 254px !important;

  mat-calendar-header {
    display: none;
  }
}

app-chat {
  display: flex;
}

// Flex
.d-flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-auto { flex: auto; };
.flex-100 { flex: 100%; };
.flex-1-1-100 { flex: 1 1 100%; }
.flex-1-1-auto { flex: 1 1 auto; }
.justify-content-center { justify-content: center; }
.justify-content-flex-start { justify-content: flex-start; }
.justify-content-flex-end { justify-content: flex-end; }
.justify-content-space-around { justify-content: space-around; }
.justify-content-space-between { justify-content: space-between; }
.justify-content-stretch { justify-content: stretch; }
.align-items-center { align-items: center; }
.align-items-flex-end { align-items: flex-end; }
.align-items-end { align-items: end; }
.align-items-start { align-items: start; }
.align-items-stretch { align-items: stretch; }
.gap-16 { gap: 16px }
